import React, { Component } from 'react'
import styles from './sideBar.module.css'
import Button from '../../common/defaultButton/defaultButton'
import { formatPrice } from '../../../services/moneyService'
import fonts from '../../../styles/fonts.module.css'
import { localizeObject } from '../../../services/localizationService'
import { parseBackendDateToDDMMYYYY, isPastLastCancellationDate } from '../../../services/timeService'
import CancelPopup from '../makeReservation/venueComponents/venueMainContent/cancelPopup/cancelPopup'
import Notification from '../../common/notification/notification'
import SEtukorttiLogo from '../../../../static/svgs/s-etukortti_logo.svg'
import SCardLogo from '../../../../static/svgs/s-card_logo.svg'
import { injectIntl, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'

class SideBar extends Component {
  isBusinessCustomer() {
    const { people } = this.props.reservation
    if (!people) return
    if (people.company) return true

    return false
  }

  renderSCardLink() {
    return `${process.env.GATSBY_SH_URL}/${this.props.intl.locale}/s-card`
  }

  renderReservationProducts() {
    if (!this.props.reservation) return null
    const { venueReservations } = this.props.reservation

    return (
      <div>
        <div className={styles.sidebarProducts}>
          <h3 className={fonts.heading3}>
            <FormattedMessage id="reservationPage.reservationItems" />
          </h3>
          <div>{venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderProducts(venueReservation.products.package)}</div> : null))}</div>
          <div>{venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderProducts(venueReservation.products.refreshment)}</div> : null))}</div>
          <div>{venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderProducts(venueReservation.products.inventory)}</div> : null))}</div>
        </div>
        <div className={styles.rowNoBorder}>
          <div className={fonts.heading4 + ' ' + styles.reservationProductTitle + ' ' + styles.totalPriceTitle}>
            <FormattedMessage id="reservationPage.total" />
          </div>
          <div className={styles.totalPrice}>
            {formatPrice(this.props.reservation.totalPrice)} €<br />
            <div className={styles.totalPriceVat}>
              <FormattedMessage id="reservationPage.inclVat" />
            </div>
          </div>
        </div>
        {!this.isBusinessCustomer() && (
          <div className={styles.coopMemberInfo}>
            <div className={styles.coopMemberLogoContainer}>
              <SEtukorttiLogo alt="" className={styles.coopMemberLogo} />
            </div>
            <div className={styles.coopMemberInfoTextContainer}>
              <p className={fonts.smallParagraphNoMargin}>
                <FormattedMessage id="reservationPage.coopMemberPriceInfo" />
              </p>
            </div>
          </div>
        )}
        <div className={styles.coopMemberInfo}>
          <div className={styles.coopMemberLogoContainer}>
            <SCardLogo alt="" className={styles.coopMemberLogo} />
          </div>
          <div className={styles.coopMemberInfoTextContainer}>
            <p className={fonts.smallParagraphNoMargin}>
              <FormattedMessage id="reservationPage.sCardInfo" />{' '}
              <a className={fonts.textLink} href={this.renderSCardLink()} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="reservationPage.sCardLinkText" />
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderProducts(product) {
    if (!product || product.length < 1) return null
    return product.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={styles.reservationProductTitle}>
          <b>{localizeObject(item.name, this.props.intl.locale)}</b>
        </div>
        <div className={styles.price}>{item.isMinimalPricePackage ? <b>{formatPrice(item.price)} €</b> : <b>{formatPrice(this.calculateTotalRowPrice(item.price, item.count))} €</b>}</div>
        {item.isMinimalPricePackage ? (
          ''
        ) : (
          <div className={styles.reservationProductDesc}>
            {formatPrice(item.price)} € x {item.count}
          </div>
        )}
      </div>
    ))
  }

  calculateTotalRowPrice(price, count) {
    return price * count
  }

  renderTermsLink() {
    if (this.props.intl.locale === 'fi') return `${process.env.GATSBY_SH_URL}/fi/varausehdot/tilavarausehdot`
    if (this.props.intl.locale === 'en') return `${process.env.GATSBY_SH_URL}/en/terms-of-reservation/events`
    if (this.props.intl.locale === 'sv') return `${process.env.GATSBY_SH_URL}/sv/regler-och-villkor-for-bokningar/evenemang`
    if (this.props.intl.locale === 'et') return `${process.env.GATSBY_SH_URL}/en/terms-of-reservation/events`
    return `${process.env.GATSBY_SH_URL}/fi/varausehdot/tilavarausehdot`
  }

  renderReservationChanges() {
    const reservation = this.props.reservation

    if (!reservation) return null

    const pastLastCancellationDate = isPastLastCancellationDate(reservation.lastCancellationDate)
    return (
      <div>
        <h3 className={fonts.heading3}>
          <FormattedMessage id="reservationPage.reservationChanges" />
        </h3>
        {this.renderCancelationContactInfo(reservation)}
        {this.renderIsReservationUpdatableInfo(reservation.locked, pastLastCancellationDate, reservation.lastCancellationDate)}
        <div className={fonts.paragraph}>
          {/* TODO: Update link */}
          <a href={this.renderTermsLink()} className={fonts.textLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="reservationPage.termsAndConditions" />
          </a>
        </div>
        {this.renderCancelButton(reservation.locked, pastLastCancellationDate)}
      </div>
    )
  }

  renderCancelationContactInfo(reservation) {
    const message = []
    if (reservation.phoneNumber) {
      message.push(this.props.intl.formatMessage({ id: 'reservationPage.notifySalesServiceDesc2' }) + ' ')
      message.push(<b key={reservation.phoneNumber}>{reservation.phoneNumber}</b>)
    }

    if (reservation.email) {
      if (message.length > 0) message.push(' ' + this.props.intl.formatMessage({ id: 'reservationPage.or' }))

      message.push(' ' + this.props.intl.formatMessage({ id: 'reservationPage.notifySalesServiceDesc3' }) + ' ')
      message.push(
        <a className={fonts.textLink} key={reservation.email} href={'mailto:' + reservation.email}>
          {reservation.email}
        </a>
      )
    }

    if (message.length === 0) return

    return (
      <p className={fonts.paragraph}>
        <FormattedMessage id="reservationPage.notifySalesServiceDesc1" />
        &nbsp;
        {message}.
      </p>
    )
  }

  renderIsReservationUpdatableInfo(locked, pastLastCancellationDate, lastCancellationDate) {
    if (locked)
      return (
        <div className={fonts.paragraph}>
          <Notification type="info" text={this.props.intl.formatMessage({ id: 'reservationPage.reservationIsBeingProcessed' })} visible />
        </div>
      )

    if (pastLastCancellationDate)
      return (
        <div className={fonts.paragraph}>
          <Notification type="info" text={this.props.intl.formatMessage({ id: 'reservationPage.lastFreeCancellationDateGone' })} visible />
        </div>
      )

    return (
      <div className={fonts.paragraph}>
        <FormattedMessage id="reservationPage.lastCancelationDate" /> <b>{parseBackendDateToDDMMYYYY(lastCancellationDate)}</b>.
      </div>
    )
  }

  renderCancelButton(locked, pastLastCancellationDate) {
    if (locked) return
    if (pastLastCancellationDate) return

    return <Button type="smallDestructive" buttonText={this.props.intl.formatMessage({ id: 'reservationPage.cancelReservation' })} onClick={() => this.props.toggleCancelPopup()} />
  }

  render() {
    return (
      <div className={styles.sideBar}>
        {this.props.cancelPopupOpen && (
          <CancelPopup
            messageBody={this.props.intl.formatMessage({ id: 'reservationPage.wannaCancelAreYouSure' })}
            messageHeading={this.props.intl.formatMessage({ id: 'reservationPage.wannaCancel' })}
            cancelButtonText={this.props.intl.formatMessage({ id: 'reservationPage.cancelReservation' })}
            notAfterAllButtonText={this.props.intl.formatMessage({ id: 'reservationPage.notAfterAll' })}
            closeClick={this.props.toggleCancelPopup}
            changeUserMessage={message => this.props.changeUserMessage(message)}
            afterCancelReservationSuccess={this.props.afterCancelReservationSuccess}
            userMessage={this.props.userMessage}
          />
        )}
        <div className={styles.contentContainer}>{this.renderReservationProducts()}</div>
        <div className={styles.contentContainer}>{this.renderReservationChanges()}</div>
      </div>
    )
  }
}

export default injectIntl(SideBar)
