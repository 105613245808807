import React from 'react'
import { connect } from 'react-redux'
import { changeReservationId, changeReservationLastname, changeReservationURLPart, changeReservation, changeConfirmReservation, changeVenueProducts } from '../../../state/reduxApp'
import VenueProducts from '../../queries/venueProductsQuery'
import Navigation from '../../navigation/navigation'
import Footer from '../../footer/footer'
import styles from './reservationDetails.module.css'
import fonts from '../../../styles/fonts.module.css'
import MainContent from './mainContent'
import SideBar from './sideBar'
import Notification from '../../common/notification/notification.js'
import { injectIntl, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'

/*
  Reservation
*/
class ReservationDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cancelPopupOpen: false,
    }

    // TODO: Make sure that reservation still exists.
    // E.g. you cancel reservation and then refresh the page. Gatsby-browser Link-state handling fills data
    this.handleFetchAnotherReservation = this.handleFetchAnotherReservation.bind(this)
    this.toggleCancelPopup = this.toggleCancelPopup.bind(this)
  }

  // TODO: Add type error / success
  // changeUserMessage(message) {
  //   this.setState({ userMessage: message })
  // }

  toggleCancelPopup() {
    const open = this.state.cancelPopupOpen
    this.setState({ cancelPopupOpen: !open })
  }

  renderReservationNumber() {
    const { reservation } = this.props

    return (
      <div className={styles.headerWrapper}>
        <h1 className={fonts.heading1}>
          <FormattedMessage id="reservationPage.yourReservation" /> {reservation.id}
        </h1>
        <button className={fonts.textLink} onClick={this.handleFetchAnotherReservation}>
          <FormattedMessage id="reservationPage.searchAnotherReservation" />
        </button>
      </div>
    )
  }

  renderReservationSuccessNotification() {
    return (
      <Notification
        type="success"
        text={this.props.intl.formatMessage({ id: 'reservationPage.reservationSuccessMessage' })}
        closable="true"
        visible={this.showNotification()}
        onClick={() => this.hideNotification()}
      />
    )
  }

  showNotification() {
    if (!this.props.reservation || !this.props.confirmReservation || !this.props.reservation.id || !this.props.confirmReservation.id) return false
    if (this.props.reservation.id === this.props.confirmReservation.id) return true
    return false
  }

  hideNotification() {
    this.props.dispatchConfirmReservation(undefined)
  }

  renderReservation() {
    const updatableVenueReservations = this.props.reservation.venueReservations.filter(vr => vr.venue.onlineReservable)
    const isUpdatable = updatableVenueReservations.length === 1 && !this.props.reservation.locked
    return (
      <div className={styles.container}>
        {/* In MVP version it is only allowed to update refreshments for reservations with one online reservable venue */}
        {updatableVenueReservations.length === 1 ? <VenueProducts updatableVenueReservation={updatableVenueReservations[0]} /> : ''}
        <MainContent
          reservation={this.props.reservation}
          renderReservationNumber={() => this.renderReservationNumber()}
          renderNotification={() => this.renderReservationSuccessNotification()}
          isUpdatable={isUpdatable}
        />
        <SideBar
          changeUserMessage={message => this.props.changeUserMessage(message)}
          userMessage={this.props.userMessage}
          reservation={this.props.reservation}
          renderReservationNumber={() => this.renderReservationNumber()}
          waitingForMutationResponse={this.props.waitingForMutationResponse}
          afterCancelReservationSuccess={this.props.afterCancelReservationSuccess}
          toggleCancelPopup={this.toggleCancelPopup}
          cancelPopupOpen={this.state.cancelPopupOpen}
        />
      </div>
    )
  }
  handleFetchAnotherReservation() {
    this.props.dispatchChangeReservationId('')
    this.props.dispatchChangeReservationLastname('')
    this.props.dispatchChangeReservationURLPart('')
    this.props.dispatchChangeReservation(undefined)
    this.props.dispatchConfirmReservation(undefined)
    this.props.dispatchVenueProducts(undefined)
  }

  render() {
    const { encryptedID } = this.props.reservation
    if (typeof window !== 'undefined') window.history.pushState({ encryptedID: encryptedID }, '', window.location.pathname + '?' + encryptedID)
    return (
      <div>
        <Navigation />
        {this.renderReservation()}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.data.reservation,
    confirmReservation: state.data.confirmReservation,
    waitingForMutationResponse: state.queryStatuses.mutatingReservation,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchChangeReservationId: callbackLink => dispatch(changeReservationId(callbackLink)),
  dispatchChangeReservationLastname: callbackLink => dispatch(changeReservationLastname(callbackLink)),
  dispatchChangeReservationURLPart: callbackLink => dispatch(changeReservationURLPart(callbackLink)),
  dispatchChangeReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchConfirmReservation: callbackLink => dispatch(changeConfirmReservation(callbackLink)),
  dispatchVenueProducts: callbackLink => dispatch(changeVenueProducts(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReservationDetails))
