import React, { Component } from 'react'
import styles from './mainContent.module.css'
import { localizeObject } from '../../../services/localizationService'
import { parseBackendDateToDDMMYYYY, parseBackendDateToTime, isPastLastCancellationDate } from '../../../services/timeService'
import fonts from '../../../styles/fonts.module.css'
import CalendarIcon from '../../../../static/svgs/calendar.svg'
import ClockIcon from '../../../../static/svgs/clock.svg'
import EuroIcon from '../../../../static/svgs/euro.svg'
import PersonIcon from '../../../../static/svgs/person.svg'
import TableIcon from '../../../../static/svgs/table.svg'
import { formatPrice } from '../../../services/moneyService'
import UpdateRefreshmentsButton from '../buttons/updateRefreshmentsButton'
import SEtukorttiLogo from '../../../../static/svgs/s-etukortti_logo.svg'
import { injectIntl, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'
import Notification from '../../common/notification/notification'

/*  Props
    -reservation
*/
class reservationMainContent extends Component {
  renderHotel() {
    const { reservation } = this.props

    if (!reservation || !reservation.id) return null
    if (!reservation.establishment) return null

    return (
      <div>
        <h3 className={fonts.heading3}>
          <FormattedMessage id="reservationPage.hotel" />
        </h3>
        <h4 className={fonts.heading4 + ' ' + styles.info}>{localizeObject(reservation.establishment.name, this.props.intl.locale)}</h4>
        {this.renderAddress()}
        {reservation.phoneNumber && (
          <>
            <div className={styles.info}>
              <FormattedMessage id="reservationPage.phoneAbbreviation" /> {reservation.phoneNumber}
            </div>
            <a href={this.renderPhonePriceLinkUrl()} target="_blank" rel="noopener noreferrer" className={fonts.textLink + ' ' + styles.info}>
              {this.props.intl.formatMessage({ id: 'reservationPage.phonePrices' })}
            </a>
          </>
        )}
      </div>
    )
  }

  renderPhonePriceLinkUrl() {
    if (this.props.intl.locale === 'fi') return process.env.GATSBY_SH_URL + '/yhteystiedot-ja-puheluiden-hinnat'
    if (this.props.intl.locale === 'en') return process.env.GATSBY_SH_URL + '/en/contacts-and-call-rates'
    if (this.props.intl.locale === 'sv') return process.env.GATSBY_SH_URL + '/sv/kontaktuppgifter-och-samtalsavgifter'
    // Heidi Haapio 23.11.2023: Most part of chain content is going to be in FI, EN, SV.
    if (this.props.intl.locale === 'et') return process.env.GATSBY_SH_URL + '/en/contacts-and-call-rates'
    return null
  }

  renderAddress() {
    const { reservation } = this.props
    if (!reservation || !reservation.id) return null
    if (!reservation.address) return null

    return (
      <div className={styles.info}>
        {reservation.address.streetAddress ? localizeObject(reservation.address.streetAddress, this.props.intl.locale) : ''},&nbsp;
        {reservation.address.zipCode ? reservation.address.zipCode : ''}&nbsp;
        {reservation.address.municipality ? localizeObject(reservation.address.municipality, this.props.intl.locale) : ''}
        {reservation.address.country ? localizeObject(reservation.address.municipality, this.props.country) : ''}
      </div>
    )
  }

  renderVenueReservations() {
    const { venueReservations } = this.props.reservation

    if (!venueReservations || venueReservations.length < 1) return null

    return (
      <div>
        <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'reservationPage.reservedVenues' })}</h3>
        {venueReservations.map((venueReservation, index) => (
          <div key={index} className={styles.reservationsVenue}>
            {this.renderVenue(venueReservation)}
          </div>
        ))}
      </div>
    )
  }

  renderVenue(venueReservation) {
    if (!venueReservation || !venueReservation.venue) return null

    var start = venueReservation.period.start
    var startDate = parseBackendDateToDDMMYYYY(start)
    var startTime = parseBackendDateToTime(start)

    var end = venueReservation.period.end
    var endDate = parseBackendDateToDDMMYYYY(end)
    var endTime = parseBackendDateToTime(end)

    return (
      <>
        <h4 className={fonts.heading4}>{localizeObject(venueReservation.venue.name, this.props.intl.locale)}</h4>
        <div>
          <div className={styles.reservationsDataCell}>
            <CalendarIcon alt="Calendar icon" className={styles.icon} />
            <div className={styles.text}>
              {venueReservation.period ? startDate : ''} {venueReservation.period ? startTime : ''} - {venueReservation.period && startDate === endDate ? '' : endDate}{' '}
              {venueReservation.period ? endTime : ''}
            </div>
          </div>
          <div className={venueReservation.personCount ? styles.reservationsDataCell : styles.hide}>
            <PersonIcon alt="Person Icon" className={styles.icon} />
            <div className={styles.text}>{venueReservation.personCount}</div>
          </div>
          <div className={venueReservation.tableConfiguration ? styles.reservationsDataCell : styles.hide}>
            <TableIcon alt="Table Icon" className={styles.icon} />
            <div className={styles.text}>{this.renderTableTitle(venueReservation.tableConfiguration)}</div>
          </div>
        </div>
      </>
    )
  }

  renderTableTitle(tabletype) {
    if (!tabletype) return ''

    tabletype = tabletype.toLowerCase()
    let tableTitle = undefined

    switch (tabletype) {
      case 'diplomat':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.diplomat' })
        break
      case 'class_shape':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.class_shape' })
        break
      case 'groupwork':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.groupwork' })
        break
      case 'u_shape':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.u_shape' })
        break
      case 'chairrow':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.chairrow' })
        break
      case 'cocktail':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' })
        break
      case 'round':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.round' })
        break
      case 'eat_buffet':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_buffet' })
        break
      case 'eat_tableservice':
        tableTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_tableservice' })
        break
      default:
        tableTitle = undefined
        break
    }

    return tableTitle
  }

  hasMinimumPricePackage() {
    const { venueReservations } = this.props.reservation
    if (!venueReservations || venueReservations.length < 1) return false
    if (!venueReservations[0].products || !venueReservations[0].products.package || venueReservations[0].products.package.length < 1) return false

    // This is based on assumption that there is only one package
    if (venueReservations[0].products.package[0].isMinimalPricePackage) return true
    return false
  }

  renderReservationPackages() {
    const { venueReservations } = this.props.reservation
    if (!venueReservations || venueReservations.length < 1) return null

    const minimumrent = this.hasMinimumPricePackage()

    return (
      <div className={styles.smallBottomPadding}>
        <h3 className={fonts.heading3}>{minimumrent ? this.props.intl.formatMessage({ id: 'reservationPage.minimumRent' }) : this.props.intl.formatMessage({ id: 'reservationPage.package' })}</h3>
        {venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderPackages(venueReservation.products.package)}</div> : null))}
      </div>
    )
  }

  renderPackages(packages) {
    if (!packages) return null
    if (packages.length < 1) return null

    let packageDescription = localizeObject(packages[0].description, this.props.intl.locale)
    let br = /<br \/>/gi
    let regex = /(<([^>]+)>)/gi

    packageDescription = packageDescription
      .replace(br, '\n')
      .replace(regex, ' ')
      .trim()
      .split('\n')

    return packages.map((item, index) => (
      <div key={index} className={styles.rowNoBorder}>
        <h4 className={fonts.heading4}>{localizeObject(item.name, this.props.intl.locale)}</h4>

        {item.count && !item.isMinimalPricePackage ? (
          <div className={styles.packageCount}>
            <PersonIcon alt="Person Icon" className={styles.icon} />
            <div className={styles.text}>{item.count}</div>
          </div>
        ) : (
          ''
        )}

        <div className={styles.packagePrice}>
          <div className={styles.prices}>
            <div className={styles.price}>
              <EuroIcon alt="Euro Icon" className={styles.icon} />
              <p className={fonts.normalPrice}>
                {formatPrice(item.price)} € {item.isMinimalPricePackage ? '' : ' / ' + this.props.intl.formatMessage({ id: 'reservationPage.person' })}
              </p>
            </div>
            {item.coopMemberPrice && !this.isBusinessCustomer() ? (
              <div className={styles.price}>
                <p className={fonts.coopMemberPrice}>
                  {formatPrice(item.coopMemberPrice)} € {item.isMinimalPricePackage ? '' : ' / ' + this.props.intl.formatMessage({ id: 'reservationPage.person' })}
                </p>
                <SEtukorttiLogo alt="Asiakasomistajahinta" className={styles.coopMemberLogo} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className={styles.packageDescription}>
          {packageDescription.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    ))
  }

  renderTimetable() {
    const { venueReservations } = this.props.reservation

    if (!venueReservations || venueReservations.length < 1) return null

    const items = []

    var index = 0
    venueReservations.forEach(vr => {
      if (vr.servingTime) {
        vr.servingTime.forEach(t => {
          items.push(this.renderServingItemTime(t, index))
          index++
        })
      }
    })

    if (items.length < 1) return null

    return (
      <div>
        <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'reservationPage.timetable' })}</h3>
        {items}
      </div>
    )
  }

  renderServingItemTime(servingItem, index) {
    return (
      <div className={styles.row} key={index}>
        <div className={styles.reservationsDataCell}>
          <ClockIcon alt="Clock icon" className={styles.icon} />
          <div className={styles.text}>
            <span className={fonts.bold}>{servingItem.time}</span>
          </div>

          <div className={styles.text}>{localizeObject(servingItem.name, this.props.intl.locale)}</div>
        </div>
      </div>
    )
  }

  renderCustomer() {
    const { people } = this.props.reservation
    if (!people) return null

    return (
      <div>
        <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'reservationPage.reservationCreator' })}</h3>
        <h4 className={fonts.heading4}>{this.props.intl.formatMessage({ id: 'reservationPage.reservationCreatorInfo' })}</h4>
        <div className={styles.contactParagraph}>
          <div className={styles.info}>
            <b>
              {people.customer ? people.customer.firstName : ''} {people.customer ? people.customer.lastName : ''}
            </b>
          </div>
          <div className={people.customer.phone ? styles.info : ''}>{people.customer ? people.customer.phone : ''}</div>
          <div className={people.customer.email ? styles.info : ''}>{people.customer ? people.customer.email : ''}</div>
        </div>
        {this.renderCompany()}
        {this.renderCustomerAddress()}
      </div>
    )
  }

  isBusinessCustomer() {
    const { people } = this.props.reservation
    if (!people) return null

    if (people.company) return true

    return false
  }

  renderCompany() {
    const { people } = this.props.reservation
    if (!people || !people.company) return null

    return (
      <div className={styles.contactParagraph}>
        <div className={styles.info}>
          <b>{people.company.companyName}</b>
        </div>
        <div className={styles.info}>{people.company.businessID}</div>
      </div>
    )
  }

  renderCustomerAddress() {
    const { people } = this.props.reservation
    if (!people) return null

    return (
      <div className={styles.contactParagraph}>
        <div className={styles.info}>{people.customer.streetAddress}</div>
        <div className={styles.info}>
          {people.customer.zipCode}
          {people.customer.zipCode && people.customer.city ? ', ' : null}
          {people.customer.city}
        </div>
        <div className={styles.info}>{people.customer.country}</div>
      </div>
    )
  }

  renderPaymentType() {
    const { paymentType } = this.props.reservation

    if (!paymentType) return null

    return (
      <div>
        <h4 className={fonts.heading4}>{this.props.intl.formatMessage({ id: 'reservationPage.paymentType' })}</h4>
        <div className={styles.info}>{this.renderPaymentName(paymentType)}</div>
      </div>
    )
  }

  renderPaymentName(paymentType) {
    if (paymentType === 'Invoice') return this.props.intl.formatMessage({ id: 'reservationPage.invoice' })
    if (paymentType === 'Prepayed') return this.props.intl.formatMessage({ id: 'reservationPage.prepayed' })
    if (paymentType === 'PaymentOnSite') return this.props.intl.formatMessage({ id: 'reservationPage.paymentOnSite' })
    return paymentType
  }

  renderContact() {
    const { people } = this.props.reservation

    if (!people.contact) return null

    return (
      <div>
        <h4 className={fonts.heading4}>{this.props.intl.formatMessage({ id: 'reservationPage.contactPerson' })}</h4>
        <div className={styles.info}>
          {people.contact ? people.contact.firstName : ''} {people.contact ? people.contact.lastName : ''}
        </div>
        <div className={styles.info}>{people.contact ? people.contact.phone : ''}</div>
        <div className={styles.info}>{people.contact ? people.contact.email : ''}</div>
      </div>
    )
  }

  reservationHasRefreshments(reservation) {
    let hasRefreshments = false

    reservation.forEach(venue => {
      if (venue.products && venue.products.refreshment && venue.products.refreshment.length > 0) hasRefreshments = true
    })

    return hasRefreshments
  }

  renderReservationRefreshments() {
    const { venueReservations } = this.props.reservation

    if (!venueReservations || venueReservations.length < 1) return null

    const hasRefreshments = this.reservationHasRefreshments(venueReservations)

    // If there are no refreshments to show and it's not possible to add them, do not render refreshments at all
    if (!hasRefreshments && !this.props.isUpdatable) return null
    if (!hasRefreshments && isPastLastCancellationDate(this.props.reservation.lastCancellationDate)) return null

    return (
      <div>
        <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'products.refreshments' })}</h3>
        {venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderRefreshment(venueReservation.products.refreshment)}</div> : null))}
        {hasRefreshments ? '' : <div className={fonts.basicParagraphNoMargin}>{this.props.intl.formatMessage({ id: 'reservationPage.noRefreshmentsDesc' })}</div>}
        <div className={styles.buttonRow}>
          <UpdateRefreshmentsButton
            isUpdatable={this.props.isUpdatable}
            language={this.props.intl.locale}
            buttonText={hasRefreshments ? this.props.intl.formatMessage({ id: 'reservationPage.editRefreshments' }) : this.props.intl.formatMessage({ id: 'reservationPage.noRefreshmentsButton' })}
          />
          <Notification
            type="info"
            text={this.props.intl.formatMessage({ id: 'reservationPage.lastFreeCancellationDateGone' })}
            visible={isPastLastCancellationDate(this.props.reservation.lastCancellationDate)}
          />
        </div>
      </div>
    )
  }

  renderRefreshment(refreshments) {
    if (!refreshments || refreshments.length < 1) return null

    return refreshments.map((item, index) => (
      // Index is added to id because same id may occur more than once (f.e. if similar products are added one by one in Hessu)
      <div key={item.id + index} className={styles.row}>
        <div className={styles.name}>
          {item.count} x {localizeObject(item.name, this.props.intl.locale)}
        </div>
        <div className={styles.prices}>
          <div className={styles.price}>
            <p className={fonts.normalPrice}>{formatPrice(this.calculateTotalRowPrice(item.price, item.count))} €</p>
          </div>
          {item.coopMemberPrice && !this.isBusinessCustomer() ? (
            <div className={styles.price}>
              <p className={fonts.coopMemberPrice}>{formatPrice(this.calculateTotalRowPrice(item.coopMemberPrice, item.count))} €</p>
              <SEtukorttiLogo alt="Asiakasomistajahinta" className={styles.coopMemberLogo} />
            </div>
          ) : (
            ''
          )}
          {/* TODO: should this be unit price  ? */}
        </div>
      </div>
    ))
  }

  reservationHasAllergies(reservation) {
    let hasAllergies = false

    reservation.forEach(venue => {
      if (venue.allergies && venue.allergies.length > 0) hasAllergies = true
    })

    return hasAllergies
  }

  renderReservationAllergies() {
    const { venueReservations } = this.props.reservation

    if (!venueReservations || venueReservations.length < 1) return null

    const hasAllergies = this.reservationHasAllergies(venueReservations)

    return (
      hasAllergies && (
        <div>
          <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'reservationPage.allergies' })}</h3>
          <div className={fonts.basicParagraphNoMargin}>
            {venueReservations.map((venueReservation, index) => (venueReservation.allergies ? <div key={index}>{venueReservation.allergies}</div> : null))}
          </div>
        </div>
      )
    )
  }

  reservationHasTechs(reservation) {
    let hasTechs = false

    reservation.forEach(venue => {
      if (venue.products && venue.products.inventory && venue.products.inventory.length > 0) hasTechs = true
    })

    return hasTechs
  }

  renderReservationTechs() {
    const { venueReservations } = this.props.reservation
    if (!venueReservations || venueReservations.length < 1) return null
    if (!this.reservationHasTechs(venueReservations)) return null

    return (
      <div>
        <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'reservationPage.techs' })}</h3>
        {venueReservations.map((venueReservation, index) => (venueReservation.products ? <div key={index}>{this.renderTechs(venueReservation.products.inventory)}</div> : null))}
      </div>
    )
  }

  renderTechs(techs) {
    if (!techs || techs.length < 1) return null
    // Index is added to id because same id may occur more than once (f.e. if similar products are added one by one in Hessu)
    return techs.map((item, index) => (
      <div key={item.id + index} className={styles.row}>
        <div className={styles.name}>
          {item.count} x {localizeObject(item.name, this.props.intl.locale)}
        </div>
        <div className={styles.prices}>
          <div className={styles.price}>
            <p className={fonts.normalPrice}>{formatPrice(this.calculateTotalRowPrice(item.price, item.count))} €</p>
          </div>
        </div>
        {item.coopMemberPrice && !this.isBusinessCustomer() ? (
          <div className={styles.price}>
            <p className={fonts.coopMemberPrice}>{formatPrice(this.calculateTotalRowPrice(item.coopMemberPrice, item.count))} €</p>
            <SEtukorttiLogo alt="Asiakasomistajahinta" className={styles.coopMemberLogo} />
          </div>
        ) : (
          ''
        )}
      </div>
    ))
  }

  calculateTotalRowPrice(price, count) {
    return price * count
  }

  renderOtherInfo() {
    const { venueReservations } = this.props.reservation

    if (!venueReservations || venueReservations.length < 1) return null

    if (!this.reservationHasEventTitle(venueReservations) && !this.props.reservation.eventDescription && !this.props.reservation.additionalRequirements) return null

    return (
      <>
        <h3 className={fonts.heading3}>
          <FormattedMessage id="reservationPage.otherInformation" />
        </h3>
        {this.renderReservationType()}
        {this.renderEventTitle()}
        {this.renderReservationOtherWishes()}
      </>
    )
  }

  renderReservationType() {
    if (!this.props.reservation.eventDescription) return null

    return (
      <div className={styles.infoRow}>
        <h4 className={fonts.heading4}>
          <FormattedMessage id="reservationPage.typeOfEvent" />
        </h4>
        <div className={fonts.basicParagraph}>{this.props.reservation.eventDescription}</div>
      </div>
    )
  }

  reservationHasEventTitle(reservation) {
    let hasTitle = false
    reservation.forEach(venue => {
      if (venue.eventTitle && venue.eventTitle.length > 0) hasTitle = true
    })
    return hasTitle
  }

  renderEventTitle() {
    const { venueReservations } = this.props.reservation
    if (!venueReservations || venueReservations.length < 1) return null

    return (
      this.reservationHasEventTitle(venueReservations) && (
        <div className={styles.infoRow}>
          <h4 className={fonts.heading4}>
            <FormattedMessage id="reservationPage.nameInSigns" />
          </h4>
          <div className={fonts.basicParagraph}>{venueReservations.map((venueReservation, index) => (venueReservation.eventTitle ? <div key={index}>{venueReservation.eventTitle}</div> : null))}</div>
        </div>
      )
    )
  }

  renderReservationOtherWishes() {
    if (!this.props.reservation.additionalRequirements) return null

    return (
      <div className={styles.infoRow}>
        <h4 className={fonts.heading4}>
          <FormattedMessage id="reservationPage.otherRequests" />
        </h4>
        <div className={fonts.basicParagraphNoMargin}>{this.props.reservation.additionalRequirements}</div>
      </div>
    )
  }

  renderAgreed() {
    if (!this.props.reservation.notes) return null

    return (
      <div>
        <h3 className={fonts.heading3}>
          <FormattedMessage id="reservationPage.agreedWithSalesService" />
        </h3>
        <div className={fonts.basicParagraphNoMargin}>{this.props.reservation.notes}</div>
      </div>
    )
  }

  render() {
    const { reservation } = this.props
    if (!reservation || !reservation.id) return <FormattedMessage id="reservationPage.reservationNotFound" />

    return (
      <div className={styles.mainContent}>
        <div className={fonts.basicParagraphNoMargin}>
          {this.props.renderNotification()}
          {this.props.renderReservationNumber()}
          <div className={styles.card}>
            {this.renderHotel()}
            {this.renderVenueReservations()}
          </div>
          <div className={styles.card}>{this.renderTimetable()}</div>
          <div className={styles.card}>{this.renderReservationPackages()}</div>
          <div className={styles.card}>{this.renderReservationRefreshments()}</div>
          <div className={styles.card}>{this.renderReservationTechs()}</div>
          <div className={styles.card}>{this.renderReservationAllergies()}</div>
          <div className={styles.card}>{this.renderOtherInfo()}</div>
          <div className={styles.card}>
            {this.renderCustomer()}
            {this.renderPaymentType()}
            {this.renderContact()}
          </div>
          <div className={styles.card}>{this.renderAgreed()}</div>
        </div>
      </div>
    )
  }
}

export default injectIntl(reservationMainContent)
