import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { changeReservation, changeCancelReservation, setQueryError, changeReservationId, changeReservationLastname, changeReservationURLPart } from '../../state/reduxApp'
import { localizedStringFragments } from './meetingRoomsQuery'

class ReservationQuery extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  render() {
    var { reservationID, customerLastName, encryptedID } = this.props
    this.props.dispatchCancelReservation(undefined)

    return (
      <Query query={RESERVATION_QUERY_STRING} variables={{ reservationID, customerLastName, encryptedID }} fetchPolicy="network-only" errorPolicy="all">
        {({ data, loading, error }) => {
          if (loading) return null

          if (error) {
            this.props.dispatchQueryError(error)
            this.props.dispatchChangeReservationId('')
            this.props.dispatchChangeReservationLastname('')
            this.props.dispatchChangeReservationURLPart('')
            return null
          }

          this.props.dispatchReservation(data.reservation)
          this.props.dispatchQueryError(undefined)
          return null
        }}
      </Query>
    )
  }
}

const mapStateToProps = state => ({
  reservationID: state.search.reservationId,
  customerLastName: state.search.reservationLastname,
  encryptedID: state.search.reservationURLEncryptedPart,
})

const mapDispatchToProps = dispatch => ({
  dispatchReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchCancelReservation: callbackLink => dispatch(changeCancelReservation(callbackLink)),
  dispatchQueryError: callbackLink => dispatch(setQueryError(callbackLink)),
  dispatchChangeReservationId: callbackLink => dispatch(changeReservationId(callbackLink)),
  dispatchChangeReservationLastname: callbackLink => dispatch(changeReservationLastname(callbackLink)),
  dispatchChangeReservationURLPart: callbackLink => dispatch(changeReservationURLPart(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationQuery)

// TODO: Move to common query fragments file
let reservationFragments = {}
reservationFragments.regularReservationProducts = gql`
  fragment regularReservationProducts on RegularReservationProduct {
    count
  }
`

reservationFragments.packageReservationProducts = gql`
  fragment packageReservationProducts on PackageReservationProduct {
    count
    isMinimalPricePackage
  }
`

// TODO: Create reservation fragment
const RESERVATION_QUERY_STRING = gql`
  query Reservation($reservationID: ID, $customerLastName: String, $encryptedID: String) {
    reservation(input: { reservationID: $reservationID, customerLastName: $customerLastName, encryptedID: $encryptedID }) {
      id
      encryptedID
      eventType
      paymentType
      useCoopMemberPrices
      lastCancellationDate
      additionalRequirements
      eventDescription
      totalPrice
      notes
      locked
      establishment {
        name {
          ...localizedString
        }
      }
      address {
        streetAddress {
          ...localizedString
        }
        zipCode
        municipality {
          ...localizedString
        }
        country {
          ...localizedString
        }
      }
      phoneNumber
      email
      people {
        customer {
          firstName
          lastName
          email
          customerProfileID
          streetAddress
          zipCode
          city
          phone
          country
        }
        contact {
          firstName
          lastName
          email
          phone
        }
        company {
          businessID
          companyName
        }
      }
      venueReservations {
        id
        venue {
          id
          name {
            ...localizedString
          }
          hessuID
          onlineReservable
        }
        period {
          start
          end
        }
        setup
        personCount
        tableConfiguration
        allergies
        eventTitle
        servingTime {
          name {
            ...localizedString
          }
          time
        }
        products {
          inventory {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            onlineSellable
            ...regularReservationProducts
          }
          package {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            description {
              ...localizedString
            }
            ...packageReservationProducts
          }
          refreshment {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            onlineSellable
            ...regularReservationProducts
          }
        }
      }
    }
  }
  ${reservationFragments.regularReservationProducts}
  ${reservationFragments.packageReservationProducts}
  ${localizedStringFragments.localizedString}
`
