import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../common/defaultButton/defaultButton'
import RefreshmentsPopup from '../makeReservation/venueComponents/venueMainContent/venueAdditionals/popup/refreshmentsPopup/refreshmentsPopup'
import { changeReservation, setMutatingReservationTrue, setMutatingReservationFalse } from '../../../state/reduxApp'
import { parsePreReservationDateTime, getCurrentTime } from '../../../services/timeService'
import { handleReservationMutationError } from '../../../services/messageService'
import { localizedStringFragments, reservationFragments } from './confirmReservationButton'
import { trackErrorEvent } from '../../../services/analyticsService'

/*
Props:
  isUpdatable  
  buttonText
  localizedContent
*/
class UpdateRefreshmentsButton extends Component {
  constructor(state) {
    super(state)

    this.state = {
      additionalServicePopup: false,
      userMessage: '',
      savingInProgress: false,
    }

    this.toggleAdditionalServicePopup = this.toggleAdditionalServicePopup.bind(this)
    this.updateReservationQuery = this.updateReservationQuery.bind(this)
    this.changeUserMessage = this.changeUserMessage.bind(this)
  }

  changeUserMessage(message) {
    this.setState({ userMessage: message })
  }

  toggleAdditionalServicePopup() {
    const open = this.state.additionalServicePopup
    this.setState({
      additionalServicePopup: !open,
    })
  }

  render() {
    if (!this.props.reduxReservation || !this.props.isUpdatable || this.areVenueProductsValid()) return null

    if (this.props.reduxReservation.lastCancellationDate) {
      const lastCancellationDate = parsePreReservationDateTime(this.props.reduxReservation.lastCancellationDate)
      if (lastCancellationDate.isBefore(getCurrentTime())) return null
    }

    return <div>{this.renderUpdateRefreshmentsButton()}</div>
  }

  // Merge existing products in reservation and all products that venue offers in one array
  renderUpdateRefreshmentsButton() {
    const refreshmentsForUpdate = []
    const updatableVenueReservation = this.props.reduxReservation.venueReservations.filter(vr => vr.venue.id === this.props.reduxVenueProducts[0].venueID).map(vr => vr)

    if (updatableVenueReservation.length !== 1) return

    const allReservationsProducts = updatableVenueReservation[0].products
    if (allReservationsProducts.refreshment) {
      allReservationsProducts.refreshment.map(r => refreshmentsForUpdate.push(r))
    }

    // Include only those products that haven't been added to reservation yet.
    const allVenueRefreshments = this.props.reduxVenueProducts[0]
    if (allVenueRefreshments.products && allVenueRefreshments.products.refreshment) {
      allVenueRefreshments.products.refreshment.filter(r => !this.containsRefreshment(refreshmentsForUpdate, r)).map(r => refreshmentsForUpdate.push(r))
    }

    return (
      <div>
        <Button type="smallDefault" onClick={() => this.toggleAdditionalServicePopup()} buttonText={this.props.buttonText} />

        {this.renderRefreshmentsPopup(refreshmentsForUpdate, updatableVenueReservation[0].id)}
      </div>
    )
  }

  renderRefreshmentsPopup(refreshmentsForUpdate, updatableVenueReservationID) {
    if (!this.state.additionalServicePopup) return
    if (!this.props.reduxVenueProducts) return
    if (this.props.reduxVenueProducts.length === 0) return

    return (
      <RefreshmentsPopup
        cancelClick={this.toggleAdditionalServicePopup}
        refreshments={refreshmentsForUpdate}
        venueReservationID={updatableVenueReservationID}
        localizedContent={this.props.localizedContent}
        onSave={this.updateReservationQuery}
        userMessage={this.state.userMessage}
        loading={this.state.savingInProgress}
      />
    )
  }

  containsRefreshment(refreshmentsForUpdate, refreshment) {
    return refreshmentsForUpdate.filter(r => r.id === refreshment.id).length > 0
  }

  areVenueProductsValid() {
    return !this.props.reduxVenueProducts || this.props.reduxVenueProducts.length !== 1
  }

  updateReservationQuery(venueSelectedRefreshments) {
    if (this.props.reduxWaitingForMutationResponse) return
    const { reduxReservation } = this.props
    if (!reduxReservation) return
    const customerLastName = reduxReservation.people.customer.lastName
    if (!customerLastName) return
    if (!reduxReservation.venueReservations) return
    if (reduxReservation.venueReservations.length < 1) return

    const personCountArray = reduxReservation.venueReservations.filter(vr => vr.id === venueSelectedRefreshments.venueReservationID).map(vr => vr.personCount)
    if (personCountArray.length !== 1) return

    const reservationID = reduxReservation.id
    const venueReservationID = venueSelectedRefreshments.venueReservationID
    const personCount = personCountArray[0]

    let locale = 'fi_FI'
    if (this.props.reduxLanguage === 'sv') locale = 'sv_FI'
    if (this.props.reduxLanguage === 'en') locale = 'en_GB'
    if (this.props.reduxLanguage === 'et') locale = 'et_EE'

    const products = []
    venueSelectedRefreshments.selectedRefreshments.map(r => products.push({ productID: r.id, count: r.count }))

    this.props.dispatchMutatingReservationTrue()
    this.changeUserMessage(handleReservationMutationError(undefined))
    this.setState({ savingInProgress: true })

    this.props.client
      .mutate({
        mutation: UPDATE_RESERVATION_QUERY_STRING,
        variables: {
          reservationID,
          customerLastName,
          locale,
          venueReservationID,
          personCount,
          products,
        },
      })
      .then(result => {
        if (!result.data || !result.data.updateReservation) {
          this.changeUserMessage(handleReservationMutationError('Missing response data error', this.props.reduxLanguage))
          this.props.dispatchMutatingReservationFalse()

          trackErrorEvent({
            action: 'updateRefreshments',
            label: 'Missing response data',
            value: result,
          })
          return
        }

        this.props.dispatchReservation(result.data.updateReservation)
        this.props.dispatchMutatingReservationFalse()
        this.toggleAdditionalServicePopup()
        this.setState({ savingInProgress: false })
      })
      .catch(error => {
        this.changeUserMessage(handleReservationMutationError(error, this.props.reduxLanguage))
        this.props.dispatchMutatingReservationFalse()
        this.setState({ savingInProgress: false })
      })
  }
}
const mapStateToProps = state => {
  return {
    reduxVenueProducts: state.data.venueProducts,
    reduxReservation: state.data.reservation,
    reduxCustomerLastName: state.search.reservationLastname,
    reduxLanguage: state.language,
    reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchMutatingReservationTrue: callbackLink => dispatch(setMutatingReservationTrue(callbackLink)),
  dispatchMutatingReservationFalse: callbackLink => dispatch(setMutatingReservationFalse(callbackLink)),
})

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(UpdateRefreshmentsButton))

// TODO: Create reservation fragment
const UPDATE_RESERVATION_QUERY_STRING = gql`
  mutation UpdateReservation($reservationID: ID!, $customerLastName: String!, $locale: Locale, $venueReservationID: ID!, $personCount: Int!, $products: [ProductInput]) {
    updateReservation(
      reservation: { reservationID: $reservationID, customerLastName: $customerLastName, locale: $locale }
      venueReservation: { id: $venueReservationID, personCount: $personCount, products: $products }
    ) {
      id
      eventType
      paymentType
      useCoopMemberPrices
      lastCancellationDate
      additionalRequirements
      eventDescription
      totalPrice
      establishment {
        name {
          ...localizedString
        }
      }
      address {
        streetAddress {
          ...localizedString
        }
        zipCode
        municipality {
          ...localizedString
        }
        country {
          ...localizedString
        }
      }
      phoneNumber
      people {
        customer {
          firstName
          lastName
          email
          customerProfileID
          streetAddress
          zipCode
          city
          phone
          country
        }
        contact {
          firstName
          lastName
          email
          phone
        }
        company {
          businessID
          companyName
        }
      }
      venueReservations {
        id
        venue {
          id
          name {
            ...localizedString
          }
          hessuID
          onlineReservable
        }
        period {
          start
          end
        }
        setup
        personCount
        tableConfiguration
        allergies
        eventTitle
        products {
          inventory {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            onlineSellable
            ...regularReservationProducts
          }
          package {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            ...packageReservationProducts
          }
          refreshment {
            id
            name {
              ...localizedString
            }
            price
            coopMemberPrice
            onlineSellable
            ...regularReservationProducts
          }
        }
      }
    }
  }
  ${reservationFragments.regularReservationProducts}
  ${reservationFragments.packageReservationProducts}
  ${localizedStringFragments.localizedString}
`
