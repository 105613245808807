import React from 'react'
import { connect } from 'react-redux'
import ReservationDetails from './reservationDetails'
import ReservationUserInput from './reservationUserInput'
import ReservationQuery from '../../queries/reservationQuery'
import SEO from '../../../components/seo/seo'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'

/*  Props:
 
*/
class ShowReservation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userMessage: undefined,
      isReservationCancelled: false,
    }

    this.changeUserMessage = this.changeUserMessage.bind(this)
    this.afterCancelReservationSuccess = this.afterCancelReservationSuccess.bind(this)
  }

  changeUserMessage(message) {
    this.setState({ userMessage: message })
  }

  afterCancelReservationSuccess() {
    this.setState({ isReservationCancelled: true })
  }

  render() {
    const { reduxReservation, reduxReservationID, reduxCustomerLastName, reduxReservationURLEncryptedPart, reduxQueryError } = this.props

    if (reduxReservation)
      return (
        <>
          <SEO title={this.props.intl.formatMessage({ id: 'reservationPage.yourReservation' })} description={this.props.intl.formatMessage({ id: 'reservationPage.yourReservation' })} />
          <ReservationDetails userMessage={this.state.userMessage} changeUserMessage={message => this.changeUserMessage(message)} afterCancelReservationSuccess={this.afterCancelReservationSuccess} />
        </>
      )

    if (reduxReservationURLEncryptedPart || (reduxReservationID && reduxCustomerLastName)) return <ReservationQuery />

    return (
      <>
        <SEO title={this.props.intl.formatMessage({ id: 'reservationPage.yourReservation' })} description={this.props.intl.formatMessage({ id: 'reservationPage.yourReservation' })} />
        <ReservationUserInput
          isReservationNotFound={reduxQueryError}
          isReservationCancelled={this.state.isReservationCancelled}
          changeUserMessage={message => this.changeUserMessage(message)}
          userMessage={this.state.userMessage}
          afterCancelReservationSuccess={this.afterCancelReservationSuccess}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  reduxReservation: state.data.reservation,
  reduxQueryError: state.data.queryError,
  reduxReservationID: state.search.reservationId,
  reduxCustomerLastName: state.search.reservationLastname,
  reduxReservationURLEncryptedPart: state.search.reservationURLEncryptedPart,
})

export default connect(mapStateToProps)(injectIntl(ShowReservation))
