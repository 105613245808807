import React from 'react'
import { connect } from 'react-redux'
import { changeReservationId, changeReservationLastname, changeReservationURLPart, changeReservation, changeConfirmReservation } from '../../../state/reduxApp'
import Navigation from '../../navigation/navigation'
import Footer from '../../footer/footer'
import styles from './reservationUserInput.module.css'
import fonts from '../../../styles/fonts.module.css'
import TextInput from '../../common/textInput/textInput'
import textInputStyles from '../../common/textInput/textInput.module.css'
import Notification from '../../common/notification/notification.js'
import DefaultButton from '../../common/defaultButton/defaultButton.js'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'
import { TYPE_SUCCESS } from '../../../services/messageService'
import { Link } from 'gatsby'
import ArrowLeft from '../../../../static/svgs/arrowleft.svg'

/*
  isReservationNotFound
*/
class ReservationUserInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // local copy of reservationID and customerLastName
      reservationID: '',
      customerLastName: '',
    }

    this.props.dispatchChangeReservationId('')
    this.props.dispatchChangeReservationLastname('')
    this.props.dispatchChangeReservationURLPart('')

    this.handleFetchReservationButtonClick = this.handleFetchReservationButtonClick.bind(this)
    this.handleReservationNumberChange = this.handleReservationNumberChange.bind(this)
    this.handleReservationLastnameChange = this.handleReservationLastnameChange.bind(this)
  }

  renderBackLink = () => {
    let pathUrl  = '/'
    if (this.props.reduxLanguage === 'fi') {
      pathUrl = '/'
    }
    if (this.props.reduxLanguage === 'sv') {
      pathUrl = '/sv'
    }
    if (this.props.reduxLanguage === 'en') {
      pathUrl = '/en'
    }
    if (this.props.reduxLanguage === 'et') {
      pathUrl = '/et'
    }

    return (
        <>
          <div className={styles.backLinkContainer}>
            <Link to={pathUrl} className={fonts.textLink} state={this.props.reduxState}>
              <ArrowLeft className={styles.arrowLeft} />
              <span>{this.props.intl.formatMessage({ id: 'search.linkToSearchPage' })}</span>
            </Link>
          </div>
        </>
    )
  }

  renderUserInput() {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.linkBack}>{this.renderBackLink()}</div>
        <h1 className={fonts.heading1}>{this.props.intl.formatMessage({ id: 'reservationPage.search.title' })}</h1>
        <form className={styles.inputContainer} onSubmit={this.isFormValid() ? this.handleFetchReservationButtonClick : null}>
          <p className={fonts.paragraph}>{this.props.intl.formatMessage({ id: 'reservationPage.search.description' })}</p>
          <div className={[textInputStyles.inputWrapper, textInputStyles.noIcon, styles.inputWrapper].join(' ')}>
            <TextInput
              id="reservationNumber"
              type="text"
              active={this.state.reservationID ? 1 : 0}
              required
              minLength="9"
              maxLength="11"
              label={this.props.intl.formatMessage({ id: 'reservationPage.search.reservationNumber' })}
              value={this.state.reservationID}
              onChange={this.handleReservationNumberChange}
            />
          </div>
          <div className={[textInputStyles.inputWrapper, textInputStyles.noIcon, styles.inputWrapper].join(' ')}>
            <TextInput
              id="reservationLastname"
              type="text"
              active={this.state.customerLastName ? 1 : 0}
              required
              minLength="1"
              maxLength="25"
              label={this.props.intl.formatMessage({ id: 'reservationPage.search.lastname' })}
              value={this.state.customerLastName}
              onChange={this.handleReservationLastnameChange}
            />
          </div>
          <div className={styles.fullWidthButton + ' ' + (this.isFormValid() ? '' : styles.disabled)}>
            <DefaultButton
              type="primary"
              onClick={this.isFormValid() ? this.handleFetchReservationButtonClick : null}
              buttonText={this.props.intl.formatMessage({ id: 'reservationPage.search.findReservation' })}
            />
          </div>
          <div className={styles.messageArea}>
            {this.renderReservationNotFound()}
            {this.renderReservationCancelled()}
          </div>
          <div>
            <p className={fonts.smallParagraph}>
              {this.props.intl.formatMessage({ id: 'reservationPage.search.areYouLookingForAccommodationReservation1' })}{' '}
              <a className={fonts.textLink} href={process.env.GATSBY_SH_URL}>
                {this.props.intl.formatMessage({ id: 'reservationPage.search.linkToFrontPageText' })}
              </a>{' '}
              {this.props.intl.formatMessage({ id: 'reservationPage.search.areYouLookingForAccommodationReservation2' })}
            </p>
          </div>
        </form>
      </div>
    )
  }

  isFormValid() {
    if (this.state.reservationID.length > 9 && this.state.customerLastName.length > 0) return true
    else return false
  }

  renderReservationNotFound() {
    if (typeof window !== 'undefined') window.history.pushState(null, '', window.location.pathname)
    return <Notification type="error" text={this.props.intl.formatMessage({ id: 'reservationPage.search.reservationNotFound' })} visible={this.props.isReservationNotFound} />
  }

  renderReservationCancelled() {
    if (!this.props.userMessage) return null
    if (this.props.userMessage.type !== TYPE_SUCCESS) return null

    return <Notification type="success" text={this.props.userMessage.body} visible={this.props.isReservationCancelled} />
  }

  handleReservationNumberChange(event) {
    event.preventDefault()
    this.setState({ reservationID: event.target.value })
  }

  handleReservationLastnameChange(event) {
    event.preventDefault()
    this.setState({ customerLastName: event.target.value })
  }

  handleFetchReservationButtonClick(event) {
    event.preventDefault()
    this.changeUserMessage(undefined)
    this.props.dispatchChangeReservation(undefined)
    this.props.dispatchConfirmReservation(undefined)
    this.props.dispatchChangeReservationId(this.state.reservationID)
    this.props.dispatchChangeReservationLastname(this.state.customerLastName)
  }

  changeUserMessage(message) {
    this.setState({ userMessage: message })
  }

  render() {
    return (
      <div className={styles.flexContainer}>
        <Navigation />
        {this.renderUserInput()}
        <Footer />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchChangeReservationId: callbackLink => dispatch(changeReservationId(callbackLink)),
  dispatchChangeReservationLastname: callbackLink => dispatch(changeReservationLastname(callbackLink)),
  dispatchChangeReservationURLPart: callbackLink => dispatch(changeReservationURLPart(callbackLink)),
  dispatchChangeReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchConfirmReservation: callbackLink => dispatch(changeConfirmReservation(callbackLink)),
})

const mapStateToProps = state => {
  return {
    reduxState: state,
    reduxLanguage: state.language,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReservationUserInput))

